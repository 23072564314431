$( ".button_hover_inner" ).mouseenter(function(e) {
	var parentOffset = $(this).offset(); 
   
	var relX = e.pageX - parentOffset.left;
	var relY = e.pageY - parentOffset.top;
	$(this).prev(".su_button_circle").css({"left": relX, "top": relY });
	$(this).prev(".su_button_circle").removeClass("desplode-circle");
	$(this).prev(".su_button_circle").addClass("explode-circle");
 
 });
 
 $( ".button_hover_inner" ).mouseleave(function(e) {
 
	  var parentOffset = $(this).offset(); 
 
	  var relX = e.pageX - parentOffset.left;
	  var relY = e.pageY - parentOffset.top;
	  $(this).prev(".su_button_circle").css({"left": relX, "top": relY });
	  $(this).prev(".su_button_circle").removeClass("explode-circle");
	  $(this).prev(".su_button_circle").addClass("desplode-circle");
 
 });

///////////// Accordeon

function initAccordeon () {
	document.querySelectorAll('.faq-item').forEach(accordeonToggle => {
	  accordeonToggle.addEventListener('click', event => {
		toggleAccordeon(event);
	  })
	});
  }
  
  function toggleAccordeon (event) {
	const currentAccordeon 	=	event.currentTarget;
	const contentHeight		=	currentAccordeon.querySelector('.faq-item .hidden p').offsetHeight;
	const contentHolder		=	currentAccordeon.querySelector('.faq-item .hidden');
  
	if (currentAccordeon.classList.contains('is-active') ) {
		currentAccordeon.classList.remove('is-active');
		contentHolder.style.maxHeight = 0;
	} else {
		contentHolder.style.maxHeight = `${contentHeight}px`;
		currentAccordeon.classList.add('is-active');
	}
  }
  
  (function() {
		initAccordeon()
  })();


///////////// Dropdown toggle 

$('.dropdown-wrapp').on('click', function() {
	$(this).find('.hidde-content').toggleClass('view');
})

///////////// Scroll Magic
$(function () { 
	gsap.registerPlugin(ScrollTrigger, SplitText);

	/// PRELOADER
	// var preloader = new SplitText('.preloader__text', {type: 'chars'});
	var preloaderTl = gsap.timeline();

	preloaderTl
	.to('.preloader__text-latter', {
		duration: .25,
		opacity: 1,
		stagger: .2,
		y:0,
		ease: 'power1. In'
	})
	// .to('.preloader__text-latter', {
	// 	duration: .25,
	// 	opacity: 0,
	// 	stagger: .2,
	// 	y:100,
	// 	ease: 'power3. inOut'
	// });


	// SMOOTH SCROLL
	let bodyScrollBar = Scrollbar.init(document.querySelector('.scrollable-area'), {
		damping: 0.1,
		delegateTo: document,
	});

	bodyScrollBar.setPosition(0, 0);
	bodyScrollBar.track.xAxis.element.remove();
	
	ScrollTrigger.scrollerProxy(".scrollable-area", {
		scrollTop(value) {
			if (arguments.length) {
				bodyScrollBar.scrollTop = value;
			}
			return bodyScrollBar.scrollTop;
		},
		getBoundingClientRect() {
			return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
		}
	});

	bodyScrollBar.addListener(ScrollTrigger.update);

	ScrollTrigger.defaults({ scroller: '.scrollable-area' });
	
	// SKEW SCROLL
	let proxy = { skew: 0 },
    skewSetter = gsap.quickSetter(".skewElem", "skewX", "deg"), // fast
    clamp = gsap.utils.clamp(-30, 30); // don't let the skew go beyond 20 degrees. 

	ScrollTrigger.create({
	onUpdate: (self) => {
		let skew = clamp(self.getVelocity() / -300);
		// only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
		if (Math.abs(skew) > Math.abs(proxy.skew)) {
		proxy.skew = skew;
		gsap.to(proxy, {skew: 0, duration: 0.8, ease: "power3", overwrite: true, onUpdate: () => skewSetter(proxy.skew)});
		}
	}
	});

	// make the right edge "stick" to the scroll bar. force3D: true improves performance
	gsap.set(".skewElem", {transformOrigin: "right center", force3D: true});

	// HEADER
	ScrollTrigger.create({
		start: 'top -80',
		end: 99999,
		toggleClass: {className: 'main-tool-bar--scrolled', targets: '.main-tool-bar'}
	}); // END HEADER

	/// First zoom screen
	if (window.matchMedia("(max-width: 768px)").matches) {
		gsap.to("#zooming", {
			scale: 1,
			duration: 1,
			borderRadius: '20px', 
			borderWidth: '5px',
			scrollTrigger: {
				trigger: '#zoomstart',
				scrub: 1,
				start: "top center",
			}
		});
	} else {
		gsap.to("#zooming", {
			scale: 1,
			duration: 1,
			borderRadius: '3vw', 
			borderWidth: '0.8vw',
			scrollTrigger: {
				trigger: '#zoomstart',
				scrub: 1,
				start: "top center",
			}
		});
	}


	// Flying text
	gsap.to(".fly-text", {
		duration: .1,
		scrollTrigger: {
			trigger: '.fly-text',
			pin: ".fly-text",
			scrub: 1,
			start: "top top",
			end: '+=7000px',
		}
	})
	gsap.to(".white-flying-text", { 
		xPercent: -250,
		duration: .1,
		scrollTrigger: {
			trigger: '.fly-text',
			// pin: ".white-flying-text",
			scrub: 1,
			start: "top center",
			end: '+=20000px',
		}
	})
	gsap.to(".border-flying-text", { 
		xPercent: 250,
		duration: .1,
		scrollTrigger: {
			trigger: '.fly-text',
			// pin: ".border-flying-text",
			scrub: 1,
			start: "top center",
			end: '+=20000px',
		}
	});
	// Zooming two screen title
	gsap.to("#title-screen", {
		opacity: 1,
		duration: 1,
		y: 0,
		scrollTrigger: {
			trigger: '#see-title-screen',
			scrub: 1,
			start: "top bottom",
		}
	});
	if (window.matchMedia("(max-width: 768px)").matches) {
		gsap.to("#zoom_screen_two", {
			scale: 1,
			duration: 1,
			borderRadius: '20px', 
			borderWidth: '5px',
			scrollTrigger: {
				trigger: '#title-screen',
				scrub: 1,
				start: "top center",
				end: "+=400px",
			}
		});
	} else {
		gsap.to("#zoom_screen_two", {
			scale: 1,
			duration: 1,
			borderRadius: '3vw', 
			borderWidth: '0.8vw',
			scrollTrigger: {
				trigger: '#title-screen',
				scrub: 1,
				start: "top center",
				end: "+=400px",
			}
		});
	}
	gsap.to(".screen .text", {
		opacity: 1,
		duration: 1,
		scrollTrigger: {
			trigger: '.screen .text',
			scrub: 1,
			start: "top center",
			end: "bottom bottom",
		}
	});
	// SLIDER 
	let sections = gsap.utils.toArray(".panel");


	if (window.matchMedia("(max-width: 768px)").matches) {

		gsap.to(sections, {
			xPercent: -100 * (sections.length - 1),
			ease: "none", // <-- IMPORTANT!
			scrollTrigger: {
				trigger: "#slideContainer",
				pin: true,
				scrub: 0.1,
				start: 'top 0',
				end: "+=2000"
			}
		});

	} else {

		gsap.to(sections, {
			xPercent: -100 * (sections.length - 1),
			ease: "none", // <-- IMPORTANT!
			scrollTrigger: {
				trigger: "#slideContainer",
				pin: true,
				scrub: 0.1,
				start: 'top 0',
				end: "+=5000"
			}
		});

	}

	// DASHBOARD
	let dash = gsap.timeline()


	if (window.matchMedia("(max-width: 768px)").matches) {

		dash.to("#dashboard", {
			duration: 1,
			scrollTrigger: {
				trigger: '.dashboard',
				scrub: 1,
				pin: '#dashboard',
				start: "top 13px",
				end: "+=2800px",
			}
		})
		dash.to("#dashboard", {
			scale: 80,
			duration: 1,
			display: 'none',
			scrollTrigger: {
				trigger: '.dashboard',
				scrub: 1,
				start: "1000px",
				end: "+=2500px",
			}
		});

	} else {

		dash.to("#dashboard", {
			duration: 1,
			scrollTrigger: {
				trigger: '.dashboard',
				scrub: 1,
				pin: '#dashboard',
				start: "top top",
				end: "+=3500px",
			}
		})
		dash.to("#dashboard", {
			scale: 80,
			duration: 1,
			display: 'none',
			scrollTrigger: {
				trigger: '.dashboard',
				scrub: 1,
				start: "1000px",
				end: "+=2500px",
			}
		});

	}



	gsap.to(".substrate", {
		// scale: 80,
		opacity: 0,
		duration: 1,
		display: 'none',
		scrollTrigger: {
			pin:'.substrate',
			trigger: '.dashboard',
			scrub: 1,
			start: "top top",
			end: "800px",
		}
	});
	/////////////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////////////
	////////////////////// MEDIA SCREEN TABLET //////////////////////////////////
	/////////////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////////////

	if (window.matchMedia("(max-width: 768px)").matches) {


		gsap.to(".dashboard-pic", {
			duration: 1,
			scrollTrigger: {
				trigger: '.dashboard',
				pin:'.dashboard-pic',
				scrub: 1,
				start: "top top",
				end: '+=1500px',
			}
		});
	} else {
		gsap.to(".dashboard-pic", {
			duration: 1,
			scrollTrigger: {
				trigger: '.dashboard',
				pin:'.dashboard-pic',
				scrub: 1,
				start: "top top",
				end: '+=2000px',
			}
		});
	}

	// CRM
	let crm = gsap.timeline()
	
	crm.to("#crm", {
		scale:1,
		duration: 1,
		opacity:1,
		scrollTrigger: {
			trigger: '.crm',
			scrub: 1,
			start: "top top",
			end: "+=1000px",
		}
	})

	crm.to(".crm", {
		scrollTrigger: {
			trigger: '.crm',
			pin: '.crm',
			scrub: 1,
			start: "0 0",
			end: "+=1500px"
		}
	});
	
	// if (window.matchMedia("(max-width: 768px)").matches) {

	// } else {


		gsap.set(".slide-crm", { zIndex: (i, target, targets) => targets.length - i });
		var images = gsap.utils.toArray('.slide-crm:not(.last)');
		images.forEach((image, i) => {
			var tlslider = gsap.timeline({
				scrollTrigger: {
					trigger: ".crm-slider",
					start: () => "top -" + (window.innerHeight*(i+0.5)),
					end: () => "+=" + window.innerHeight,
					// end: () => "+=2000",
					scrub: 2,
					toggleActions: "play none reverse none",
					invalidateOnRefresh: true,     
				}
			})
			tlslider.to(image, { height: 0 });
		});
		
		gsap.set(".slide-text-crm", { zIndex: (i, target, targets) => targets.length - i });
		var texts = gsap.utils.toArray('.slide-text-crm');
		texts.forEach((text, i) => {
			var tlslider = gsap.timeline({
				scrollTrigger: {
					trigger: ".crm-slider",
					start: () => "top -" + (window.innerHeight*i),
					end: () => "+=" + window.innerHeight * 2,
					// end: () => "+=2000",
					scrub: 2,
					toggleActions: "play none reverse none",
					invalidateOnRefresh: true,     
				}
			})
			
			tlslider.to(text, { duration: 0.33, opacity: 1, y:"-50%", top:'50%' })  
			.to(text, { duration: 0.33, opacity: 0, y:"0%", top: '0' }, 0.66);
		});
		
		ScrollTrigger.create({
			trigger: "#crmTriger",
			scrub: true,
			pin: '.crm-slider',
			duration:1,
			start: () => "top top",
			end: () => "+=" + ((images.length + 1) * window.innerHeight),
			// invalidateOnRefresh: true,
		});

	// }


	var listWidth = 10;
	var wrappServ = $('.title-services')
	
	wrappServ.find('.title-panel').each(function (i) {
		listWidth += $(this, i).outerWidth(true);
	});

	wrappServ.css({
		"width" : listWidth + "px"
	});

	// SERVICES SLIDER
	// let titlePanel = gsap.utils.toArray(".title-panel");

	// gsap.to(titlePanel, {
	// 	xPercent: -200 * ( titlePanel.length - 1 ),
	// 	duration: .1,
	// 	scrollTrigger: {
	// 		trigger: "#services",
	// 		pin: true,
	// 		scrub: 1,
	// 		snap: {
	// 			snapTo: 1 / (titlePanel.length - 1),
	// 			inertia: true,
	// 			duration: {min: 0.1, max: 0.1}
	// 		},
	// 		start: 'top top',
	// 		end: "+=" + listWidth,
	// 	}
	// });
	/* --- Split the text, Client Title --- */
	if (window.matchMedia("(max-width: 768px)").matches) {
		function splitTextServices() {
			const targets = gsap.utils.toArray(".services-slide__title");
			targets.forEach((target) => {
				let SplitType = new SplitText(target, { type: "words,chars" });
				let chars = SplitType.chars; //an array of all the divs that wrap each character
				gsap.from(chars, {
					duration: 0.8,
					opacity: 0,
					y:1000, 
					rotation:90, 
					ease: "none",
					stagger: 0.2,
					scrollTrigger: {
						trigger: target,
						pin: target,
						start: "top 75%",
						end: "+=2000px",
						scrub: true
					}
				});
			});
		}
	} 
	if (window.matchMedia("(min-width: 769px)").matches) {
		function splitTextServices() {
			const targets = gsap.utils.toArray(".services-slide__title");
			targets.forEach((target) => {
				let SplitType = new SplitText(target, { type: "words,chars" });
				let chars = SplitType.chars; //an array of all the divs that wrap each character
				gsap.from(chars, {
					duration: 0.8,
					opacity: 0,
					y:100, 
					rotation:90, 
					ease: "none",
					stagger: 0.2,
					scrollTrigger: {
						trigger: target,
						pin: target,
						start: "top 35%",
						end: "+=2000px",
						scrub: true
					}
				});
			});
		}
	}

	splitTextServices();

	// up text
	const fadeUp = gsap.utils.toArray('.fadeUp');
	fadeUp.forEach(box => {
	  gsap.to(box, { 
		opacity:1,
		y:0,
		stagger: 0.2,
		scrollTrigger: {
		  trigger: box,
		  scrub: true,
		}
	  })
	});
}); // END GSAP